.page-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    -webkit-backdrop-filter: blur(3px); 
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, 0.152);
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.5rem 1rem;
}

.page-footer img {
    width: 4rem;
    height: 2.5rem;
}

.footer-icons {
    display: flex;
}

.footer-icons a {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s ease;
    border: 1px solid #5dbc62;
}

.footer-icons a:hover {
    background-color: #5dbc62;
}

.footer-icons a:nth-child(2) {
    margin-left: 0.5rem;
}

h1 {
    font-size: 15vw;
    font-weight: normal;
    position: absolute;
    top: 30%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
}

h1 div:nth-child(1) {
    margin-right: 5vw;
}

.bg {
    background-image: url(../../../assets/fg.jpg);
}

.fg {
    background-image: url(../../../assets/bg.png);
}

.bg,
.fg {
    background-size: cover;
    background-position: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.key {
    display: inline-block;
    transition: transform 0.2s;
    color: #fdfeff;
    opacity: 85%;
    font-size: 11vw;
    font-weight: bolder;
    /* color: #fdb997; */
}

@keyframes pressDown1 {

    30%,
    40%,
    100% {
        transform: translateY(0);
    }

    35% {
        transform: translateY(10px);
    }
}

@keyframes pressDown2 {

    70%,
    80%,
    100% {
        transform: translateY(0);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes pressDown3 {

    30%,
    40%,
    100% {
        transform: translateY(0);
    }

    35% {
        transform: translateY(10px);
    }
}

@keyframes pressDown4 {

    40%,
    50%,
    100% {
        transform: translateY(0);
    }

    45% {
        transform: translateY(10px);
    }
}

@keyframes pressDown5 {

    20%,
    30%,
    100% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(10px);
    }
}

@keyframes pressDown6 {

    60%,
    70%,
    100% {
        transform: translateY(0);
    }

    65% {
        transform: translateY(10px);
    }
}

@keyframes pressDown7 {

    10%,
    20%,
    100% {
        transform: translateY(0);
    }

    15% {
        transform: translateY(10px);
    }
}

@keyframes pressDown8 {

    35%,
    45%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(10px);
    }
}

.key:nth-child(1) {
    animation: pressDown1 2s infinite;
    /* color: #5dbc62; */
}

.key:nth-child(2) {
    animation: pressDown2 3s infinite;
    /* color: #3d4d5a; */
}

.key:nth-child(3) {
    animation: pressDown3 4s infinite;
    /* color: #5dbc62; */
}

.key:nth-child(4) {
    animation: pressDown4 2.5s infinite;
    /* color: #3d4d5a; */
}

.key:nth-child(5) {
    animation: pressDown5 2.5s infinite;
    /* color: #5dbc62; */
}

.key:nth-child(6) {
    animation: pressDown6 3.5s infinite;
    /* color: #3d4d5a; */
}

@media only screen and (max-width: 1400px) {
    h1 {
        top: 35%;
    }
}

@media only screen and (max-width: 1200px) {
    h1 {
        top: 38%;
    }
}

@media only screen and (max-width: 930px) {
    h1 {
        top: 42%;
    }
}

@media only screen and (max-width: 800px) {
    h1 {
        top: 46%;
    }
}

@media only screen and (max-width: 700px) {
    h1 {
        top: 50%;
    }
}

@media only screen and (max-width: 390px) {
    h1 {
        top: 51%;
    }
}

@media only screen and (max-width: 430px) {
    h1 {
        top: 51.5%;
    }
}