.content {
    position: relative;
    margin-bottom: 6rem;
}

.content h2 {
    color: #fff;
    font-size: 8em;
    position: absolute;
    transform: translate(-50%, -50%);
}

.content h2:nth-child(1) {
    color: transparent;
    -webkit-text-stroke: 2px #fff;
}

.content h2:nth-child(2) {
    color: #fff;
    animation: animate 4s ease-in-out infinite;
}

.image-cover {
    background-image: url(../../../assets/renewable.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
}

@keyframes animate {

    0%,
    100% {
        clip-path: polygon(0% 45%,
                16% 44%,
                33% 50%,
                54% 60%,
                70% 61%,
                84% 59%,
                100% 52%,
                100% 100%,
                0% 100%);
    }

    50% {
        clip-path: polygon(0% 60%,
                15% 65%,
                34% 66%,
                51% 62%,
                67% 50%,
                84% 45%,
                100% 46%,
                100% 100%,
                0% 100%);
    }
}